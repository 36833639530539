import { Grid, Typography } from '@mui/material';
import { InvoiceSummary } from '../../type/Types';
import FormattedAddress from '../../components/FormattedAddress';
import styles from '../../style/app.module.css';

interface Props {
    invoice: InvoiceSummary;
}

const Header = (props: Props) => {
    const { invoice } = props;

    return (
        <Grid container>
            <Grid item sm={6} xs={5}>
                <Typography variant='subtitle2' component='p'>
                    To
                </Typography>
            </Grid>
            <Grid item sm={6} xs={7} className={styles.sim_text_right}>
                <Typography variant='subtitle2' component='p'>
                    {invoice.clientName}
                </Typography>
            </Grid>
            <Grid item sm={6} xs={5}>
                <Typography variant='subtitle2' component='p'>
                    Reference #
                </Typography>
            </Grid>
            <Grid item sm={6} xs={7} className={styles.sim_text_right}>
                <Typography variant='subtitle2' component='p'>
                    {invoice.number}
                </Typography>
            </Grid>
            <Grid item sm={6} xs={5}>
                <Typography variant='subtitle2' component='p'>
                    Issued Date
                </Typography>
            </Grid>
            <Grid item sm={6} xs={7} className={styles.sim_text_right}>
                <Typography variant='subtitle2' component='p'>
                    {invoice.issuedDate}
                </Typography>
            </Grid>
            <Grid item sm={6} xs={5}>
                <Typography variant='subtitle2' component='p'>
                    Due Date
                </Typography>
            </Grid>
            <Grid item sm={6} xs={7} className={styles.sim_text_right}>
                <Typography variant='subtitle2' component='p'>
                    {invoice.dueDate}
                </Typography>
            </Grid>
            <Grid item sm={6} xs={5}>
                <Typography variant='subtitle2' component='p'>
                    From
                </Typography>
            </Grid>
            <Grid item sm={6} xs={7} className={styles.sim_text_right}>
                <Typography variant='subtitle2' component='p'>
                    {invoice.sellerName} <br />
                    <FormattedAddress address={invoice.sellerAddress} />
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Header;
